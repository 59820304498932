import React, { Component } from "react";
import "./Optin.css";

class Optin extends Component {
  modal() {
    const modal = document.getElementById("modal");
    modal.classList.toggle("is_open");
  }

  render() {
    return (
      <div className="optin">
        <p>Bizimle iletişime geçin.</p>
        <a href="mailto:info@erceelektrik.com.tr">info@erceelektrik.com.tr</a>
      </div>
    );
  }
}

export default Optin;
